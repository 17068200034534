<template>
  <b-row>
    <b-col>
      <validation-observer
        ref="medicalReportForm"
        v-slot="{ invalid }"
        v-if="canEdit || editingFinished"
      >
        <b-row>
          <b-col cols="12">
            <div class="position-relative form-group">
              <validation-provider
                name="medicamento"
                :rules="{ required: true }"
                v-slot="{ errors, touched }"
              >
                <multiselect
                  v-model="fields.laudo"
                  :options="laudosOptions"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  @search-change="searchMedicalReports"
                  @select="selectMedicalReports"
                  @remove="clearFields"
                  placeholder="Pesquisar laudo médico"
                  class="search-mode with-border"
                  :class="{ error: touched && errors.length > 0 }"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="single_label d-inline-block text-truncate">{{
                      props.option.name || props.option
                    }}</span>
                  </template>
                  <template slot="laudos" slot-scope="laudo">
                    <small>{{ laudo.name || laudo }}</small>
                  </template>
                  <template #option="props">
                    <div>
                      <div class="report-name">
                        <b>{{ props.option.name }}</b>
                      </div>
                      <div class="report-obs">
                        <small
                          ><b>Obs: </b>{{ props.option.obs ?? '---' }}</small
                        >
                      </div>
                    </div>
                  </template>
                  <template slot="caret">
                    <div class="search">
                      <Search class="search-icon" />
                    </div>
                  </template>
                  <template slot="noOptions"
                    >Digite para pesquisar um laudo médico</template
                  >
                  <template slot="noResult"
                    >Nenhum laudo médico encontrado</template
                  >
                </multiselect>
                <div class="new-medical-report">
                  <Icon tooltip="Limpa seleção" v-if="fields.laudo">
                    <Close class="icon close" @click="clearFields" />
                  </Icon>
                  <Icon
                    tooltip="Criar novo modelo de laudo"
                    v-if="showAddCustom()"
                  >
                    <Plus
                      class="icon"
                      @click="$bvModal.show('medical-report-modal')"
                    />
                  </Icon>
                  <Icon tooltip="Editar" v-if="!showAddCustom()">
                    <Edit
                      class="icon edit"
                      @click="$bvModal.show('medical-report-modal')"
                    />
                  </Icon>
                  <Icon tooltip="Deletar" v-if="!showAddCustom()">
                    <Trash
                      class="icon trash"
                      @click="$bvModal.show('delete-medical-report-modal')"
                    />
                  </Icon>
                </div>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="form-group">
              <label for="descricao_anotacao">Observação do laudo</label>
              <input
                id="observation"
                type="text"
                placeholder="Observação"
                class="form-control"
                v-model="fields.observacao"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="form-group">
              <validation-provider name="´laudo" :rules="{ required: true }">
                <label for="descricao_anotacao">Descrição do laudo</label>
                <VueEditor
                  id="template"
                  class="vue_edition"
                  v-model="fields.descricao"
                  :editorToolbar="customToolbar"
                  :disabled="!fields.laudo"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-end w-100">
          <div class="display-title-check">
            <input
              name="display-title"
              type="checkbox"
              class="form-control"
              v-model="fields.displayTitle"
            />
            <span for="display-title">Exibir nome do laudo na impressão?</span>
          </div>
          <div v-if="editingIndex !== null" class="m-2">
            <b-button
              variant="danger"
              block
              :disabled="invalid"
              @click="cancelEdition"
            >
              Cancelar edição
            </b-button>
          </div>
          <div class="m-2">
            <b-button
              variant="primary"
              block
              :disabled="invalid"
              @click="addMedicalReport"
            >
              {{ editingIndex !== null ? 'Atualizar' : 'Adicionar' }}
            </b-button>
          </div>
        </b-row>
      </validation-observer>

      <b-row v-if="form.value.length">
        <b-col>
          <h5><b>Laudos adicionados</b></h5>
          <hr class="custom-hr" />
          <div
            v-for="(value, index) of form.value"
            :key="index"
            class="medical-report-item"
          >
            <div class="name-row">
              <span class="medical-report-name"
                ><b>Nome:</b> {{ value.laudo.name || value.laudo }}
                <span
                  v-if="canEdit || editingFinished"
                  :title="
                    value.displayTitle
                      ? 'Nome do laudo exibido na impressão'
                      : 'Nome do laudo oculto na impressão'
                  "
                  @click="toggleVisibility(value)"
                >
                  <transition name="fade">
                    <EyeSlash
                      class="eye eye-disabled"
                      v-if="!value.displayTitle"
                    />
                    <Eye class="eye" v-if="value.displayTitle" />
                  </transition>
                </span>
              </span>
              <div class="icons-container" v-if="canEdit || editingFinished">
                <Icon tooltip="Editar">
                  <Edit class="icon edit" @click="editItem(value, index)" />
                </Icon>
                <Icon tooltip="Deletar">
                  <Trash class="icon trash" @click="removeItem(index)" />
                </Icon>
              </div>
            </div>
            <div class="medical-report-description">
              <b>Descrição do laudo: </b>
              <div v-html="value.descricaoPreview || value.descricao"></div>
            </div>
            <span class="alert-secondary medical-report-observation"
              ><b>Observação do laudo: </b> {{ value.observacao }}</span
            >
            <hr v-if="index !== form.value.length - 1" class="custom-hr" />
          </div>
        </b-col>
      </b-row>
    </b-col>

    <MedicalReportModal
      :report="temporaryReport"
      :getMedicalReports="getMedicalReports"
      :medicalReport="selected"
      reportType="exam"
      @setMedicalReport="setMedicalReport"
      @onHide="getMedicalReports"
    />
    <DeleteMedicalReportModal
      :name="fields.laudo?.name"
      :confirmDelete="confirmDelete"
    />
  </b-row>
</template>
<script>
import { debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { getCurrentUser } from '@/utils/localStorageManager'
import { VueEditor } from 'vue2-editor'

export default {
  props: {
    path: String,
    editingFinished: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueEditor,
    MedicalReportModal: () =>
      import('@/components/Attendance/Forms/Components/MedicalReportModal'),
    DeleteMedicalReportModal: () =>
      import(
        '@/components/Attendance/Forms/Components/DeleteMedicalReportModal'
      ),
    Plus: () => import('@/assets/icons/plus.svg'),
    Icon: () => import('@/components/General/Icon'),
    Search: () => import('@/assets/icons/search.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
    Eye: () => import('@/assets/icons/eye.svg')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
      form: state => state.attendance.form.laudoMedico
    })
  },
  data() {
    return {
      user: getCurrentUser(),
      fields: this.getDefaultFields(),
      touched: false,
      errors: [],
      isLoading: false,
      laudosOptions: [],
      laudosMedicos: [],
      temporaryReport: '',
      selected: {},
      editingIndex: null,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }] // dropdown with defaults from theme
      ]
    }
  },
  created() {
    this.debounceChangeDescription = debounce(this.changeDescription, 500)
  },
  async mounted() {
    await this.getMedicalReports()
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord']),
    ...mapActions('attendance/form/laudoMedico', [
      'handleFields',
      'handleProps'
    ]),
    getDefaultFields() {
      return {
        laudo: null,
        descricao: null,
        professional_id: null,
        path: null
      }
    },
    addMedicalReport() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }
      this.fields.descricao = this.fields.descricao.replace(
        /(?:\r\n|\r|\n)/g,
        '<br>'
      )
      this.fields.descricaoPreview = this.fields.descricao
      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
      this.selected = {}
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.descricao = value ? searchTerm : null
    },
    showAddCustom() {
      return !this.selected || !Object.keys(this.selected).length
    },
    cancelEdition() {
      this.editingIndex = null
      this.fields = this.getDefaultFields()
    },
    async confirmDelete(confirm) {
      this.$bvModal.hide('delete-medical-report-modal')
      if (confirm) await this.deleteMedicalReport()
    },
    async deleteMedicalReport() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteMedicalReport(this.selected.id)
        await this.getMedicalReports()
        this.selected = {}
        this.fields = this.getDefaultFields()
        this.$toast.success('Modelo de laudo excluído com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    searchMedicalReports(query) {
      if (query) {
        this.selected = {}
        this.temporaryReport = query
      }
      const queryArray = query.split(' ')
      this.laudosOptions = []
      this.laudosMedicos.map(laudo => {
        let check = true
        const nameArray = laudo.name.split(' ')
        const obsArray = laudo.observation?.split(' ')

        for (const item of queryArray) {
          const matchName = this.checkMatchingResult(item, nameArray)
          const matchObs = this.checkMatchingResult(item, obsArray)

          if (!matchName && !matchObs) {
            check = false
            break
          }
        }

        if (check) {
          this.laudosOptions.push({ name: laudo.name, obs: laudo.observation })
        }
      })
    },
    checkMatchingResult(str, array) {
      if (array?.length) {
        for (const item of array) {
          if (item.toLowerCase().includes(str.toLowerCase())) {
            return true
          }
        }
        return false
      }
      return false
    },
    selectMedicalReports(query) {
      this.laudosMedicos.find(laudoMedico => {
        if (laudoMedico.name === query.name) {
          this.fields.descricao = laudoMedico.template
          this.fields.observacao = laudoMedico.observation
          this.fields.professional_id = laudoMedico.professional_id
          this.fields.laudo = laudoMedico.name
          if (
            laudoMedico.professional_id &&
            laudoMedico.professional_id === this.user.id
          ) {
            this.selected = laudoMedico
          } else {
            this.selected = {}
          }
        }
      })
    },
    async getMedicalReports() {
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      const type = ['exam', 'attendance']
      try {
        const response = await this.api.getMedicalReports(clinic.id, type)
        this.laudosMedicos = response.data
        this.laudosOptions = response.data.map(laudo => {
          return { name: laudo.name, obs: laudo.observation }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setMedicalReport(medicalReport) {
      this.fields.laudo = medicalReport.name
      this.fields.descricao = medicalReport.template
      this.fields.professional_id = medicalReport.professional_id
      this.selected = medicalReport
    },
    clearFields() {
      this.fields = this.getDefaultFields()
      this.selected = {}
    },
    changeDescription() {
      if (!this.fields.descricao) return
      const value = []
      this.fields.path = this.path
      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    toggleVisibility(newValue) {
      newValue.displayTitle = !newValue.displayTitle
      const value = [...this.form.value]
      newValue.descricao = newValue.descricao.replace(/(?:\r\n|\r|\n)/g, '<br>')
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    editItem(value, index) {
      value.descricaoPreview = value.descricao
      value.descricao = value.descricao.replaceAll('<br>', '\n')
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    }
  },
  watch: {
    form(val) {
      if (val.value.length) {
        this.fields = this.form.value[0]
        this.selected = this.laudosMedicos.find(
          el => el.name === this.fields.laudo
        )
        this.clearFields()
      }
    },
    editingFinished(val) {
      if (val) {
        this.clearFields()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
textarea {
  min-height: 200px;
  max-height: 1000px;
}

.search {
  width: 5% !important;
  div {
    cursor: pointer;
  }
}

.single_label {
  max-width: 85%;
}

.name-row {
  display: flex;
  justify-content: space-between;
}

.new-medical-report {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  gap: 10px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media (min-height: 1600) {
      font-size: 1.6vh;
    }
    @media (max-height: 1600) {
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 24px;
}
.display-title-check {
  display: flex;
  span {
    white-space: nowrap;
    align-self: center;
    text-align: center;
    margin: 0px 20px 0px 10px;
  }
  input {
    width: 20px;
    align-self: center;
  }
}
.icons-container {
  display: flex;
  .icon {
    width: 25px;
    height: 25px;
    margin: 0px 5px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--danger);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }
  }
}
</style>
